import React from 'react'

export const SvgCalendarIcon = () => {
    return (
        <div>
            <svg  width="40"
                    height="30">
                <path d='M1 3 22 3C23 3 23 4 23 5L0 5C0 4 0 3 1 3M0 6 0 22C0 23 1 24 2 24L21 24C22 24 23 23 23 22L23 6 18 6 18 15 18 15 16 15 16 13 18 13 18 11 16 11 16 9 18 9 18 6 14 6 14 19 12 19 12 17 14 17 14 15 12 15 12 13 14 13 14 11 12 11 12 9 14 9 14 6 10 6 10 19 8 19 8 17 10 17 10 15 8 15 8 13 10 13 10 11 8 11 8 9 10 9 10 6 6 6 6 19 4 19 4 17 6 17 6 15 4 15 4 13 6 13 6 6 0 6'
                    fill="white" 
                    // fill="currentColor"
                />
            </svg>

        </div>
    )
}
