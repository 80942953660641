import React, { useState } from 'react'
import { useForm } from "react-hook-form"
import axios from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import url from '../components/useURL'
import { number } from 'yup'
//import './FormDienstAdd.css'

export default function DienstPlanForm() {
    // form validation rules 
    const validationSchema = Yup.object().shape({
        dienstplan: Yup.string()
            .required('Paste data from DienstPlan'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    console.log(validationSchema)
    const [dataString, setDataString] = useState("")
    const [array, setArray] = useState([])
    const [dataArray, setDataArray] = useState([
        {
            number: "",
            from: "",
            to: "",
            place: "",
            wageH: "",
            date: "",
            r: ""
        }
    ])


    const [submitting, setSubmitting] = useState(false)
    const [send, setSend] = useState(false)

    const sendingData = () => {
        setSend(true)
    }
    function onSubmit(data) {
        // display form data on success          
        console.log(submitting)
        console.log(JSON.stringify(data))
        setDataString(JSON.stringify(data))
        dataString.split(" ").map((item, index) => {                        
            setDataArray({number: item,
            from: item})
        })
console.log(dataString)
        if (send) {
            axios.post(url + ':8087/dienstplan', data)
                .then(function (response) {
                    console.log(response)
                    // setClearValues(true)
                })
                .catch(function (error) {
                    console.log(error)
                })

            alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
            setSubmitting(true)
            return false;
        }

    }


     // Splitting Texts
    //  const SplitText = React.memo(({ str }) => {
    //     return (
    //       <div>
    //         {str.split(" ").map((item, index) => {
    //           return <div key={index}>{item}</div>;
    //         })}
    //       </div>
    //     );
    //   });  

    return (
        <div>
            <section className='form-add-container'>
                <br />
                <form onSubmit={handleSubmit(onSubmit)} className="formadd-grid-container">
                    <div className="formadd-item1">

                        <label>Data</label>
                        <input name="dienstplan" type="text" {...register('dienstplan')} className={`form-control ${errors.dienstplan ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.dienstplan?.message}</div>
                    </div>

                    {/* BUTTON DIENST MELDEN RESET */}
                    <div className="formadd-item5">

                        <button type="submit" className="button-form-add-end">Convert Data</button>
                        <button type="submit" onClick={sendingData} className="button-form-add-end">Send Data</button>
                        <button type="button" onClick={() => reset()} className="button-form-add-end">Reset</button>
                    </div>

                </form>
            </section>
            <div>
                <div>                    
                    {dataString.split(" ").map((item, index) => {
                        if(index == 0){
                            item = item.substring(15)
                        }                       
                        return <div key={index}>{item}</div>;
                    })}
                </div>
                <br /><br />

            </div>
        </div >
    )
}



